import React from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../store/store';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import axios from 'axios';
import config from '../config';

const LogoutButton = () => {
  const clearAuth = useStore(state => state.clearAuth);
  const resetStore = useStore(state => state.resetStore);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(`${config.serverUrl}/logout`);
    } catch (error) {
      console.error('Error logging out:', error);
    }
    
    clearAuth();
    resetStore();
    navigate('/login');
  };

  return (
    <Button variant="outlined" size="small" startIcon={<LogoutIcon />} onClick={handleLogout}>
      Logout
    </Button>
  );
};

export default LogoutButton;
