import React from 'react';
import SearchResultsPage from './SearchResultsPage';
import SearchWelcomeHeader from './SearchWelcomeHeader';
import SearchErrorModal from './SearchErrorModal';
import SearchBottomBanner from '../SearchBottomBanner/SearchBottomBanner';
import LoadingOverlay from './LoadingOverlay';
import useStore from '../../store/store';
const SearchMainPage = () => {
  const { isLoadingResults } = useStore();
  return (
    <>
      <LoadingOverlay loadingCondition={isLoadingResults } />
      <SearchWelcomeHeader />
      <SearchErrorModal />
      <SearchResultsPage />
      <SearchBottomBanner />
    </>
  )
}

export default SearchMainPage;