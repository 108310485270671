/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useStore from '../store/store';
import { Navigate } from 'react-router-dom';
import { CssBaseline, Container, Box, ThemeProvider, createTheme } from '@mui/material';
import TopBar from '../components/TopBar/TopBar';
import SubHeader from '../components/SubHeader';
import SearchMainPage from '../components/SearchEngine/SearchMainPage';
import useCheckUrlForSearchParams from '../components/CheckUrlForSearchParams.js';

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#112269"
    },
    background: {
      default: "#F2F2F2",
    },
  },
});

const rootStyles = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  paddingTop: {xs: '145px', sm: '110px', md: '75px', lg: '75px'},
};

const headerStyles = {
  bgcolor: "primary.main", 
};

const mainStyles = {
  flex: 1,
  py: 1,  
};

const footerStyles = {
  width: "100%",
  bgcolor: "#d9d9d9",
  color: "#525252",
  py: 2,
  mt: "auto",
  '& a': {
    color: '#525252',  // same color for links in footnote as for text
    textDecoration: 'none',  // Optional: remove underline
    '&:hover': {
      color: '#C70039',  // Optional: hover color
    },
  },
};


const HomePage = () => {
  const user = useStore(state => state.user);  

  // Check URL for search params:
  const { checkParams } = useCheckUrlForSearchParams();
  useEffect(() => {checkParams();}, []);

  if (!user?.customer) {
    return <Navigate to="/login" />;
  }

  return (
    <>      
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <Box sx={rootStyles}>
          <Box component="header" >
            <Box component="article" sx={headerStyles} >
              <TopBar />
            </Box>
            <SubHeader />
          </Box>
          <Box component="main" sx={mainStyles}>
            <Container maxWidth="lg">
              <SearchMainPage />
            </Container>
          </Box>
          <Box component="footer" sx={footerStyles}>
            <Container maxWidth="lg"></Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
export default HomePage;