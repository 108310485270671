import toast from 'react-hot-toast';
import config from '../config';
import useStore from '../store/store';

export const brandsService = async (token) => {
  try {
    const brandsResponse = await fetch(`${config.serverUrl}/brands`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
      credentials: 'include',
    });

    if (!brandsResponse.ok) {
      throw new Error('Failed to fetch brands');
    }

    const brandsData = await brandsResponse.json();
    const total_brands = brandsData.brands;
    const { user, setPricetool_brands } = useStore.getState();
    
    // Get blocked brands from user object
    const blockedBrandsString = user.blocked_brands || ""; // Ensure it's a string
    const blockedBrands = blockedBrandsString
      .split(",") // Split by comma
      .map(brand => brand.trim()) // Trim whitespace
      .filter(brand => brand !== ""); // Remove empty strings
    
    // Filter out blocked brands
    const filteredBrands = total_brands.filter(
      brand => !blockedBrands.includes(brand)
    );

    // Update Zustand state with filtered brands
    setPricetool_brands(filteredBrands);
    
  } catch (brandsError) {
    console.error('Error fetching brands:', brandsError);
    toast.error('Failed to fetch brands');
  }
};