import React from 'react';
import { Box, Grid } from '@mui/material';
import useStore from '../../store/store';
import SearchResultsList from './SearchResultsList';
import SearchFilter from './SearchFilter';

const SearchResultsPage = () => {
  const {
    hasSearched,
  } = useStore();

  return (
    <>
      {hasSearched && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <Box>
                <Box sx={{ backgroundColor: 'white', margin: 0 }}>
                  <SearchFilter />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={9}>
              <Box>
                <Box sx={{ backgroundColor: 'transparent' }}>
                  <SearchResultsList />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default SearchResultsPage;
