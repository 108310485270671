// SearchFilter.js

import React, { useCallback, useState } from 'react';
import useStore from "../../store/store";
import useHandleSearchService from "../../services/searchService";
import debounce from 'lodash/debounce';
import { 
  Box, 
  Paper, 
  Skeleton, 
  Switch, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography, 
  Button, 
  Drawer, 
  useMediaQuery 
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const SearchFilter = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const {
    brands_in_results,
    filter__brands,
    setFilter__brands,
    filter__onstock,
    setFilter__onstock,
    isLoadingResults,
    onStock_in_results,
  } = useStore();

  const { handleSearchService } = useHandleSearchService();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async () => {
      // Call API after updating filter:
      await handleSearchService();
    }, 300), []
  );

  const handle_CB_brand = (brand) => {
    setFilter__brands(
      filter__brands.includes(brand)
        ? filter__brands.filter((b) => b !== brand)
        : [...filter__brands, brand]
    );
    // Avoid unnecessary API calls:
    debouncedSearch();
  };

  const handle_CB_stock = (onStock) => {
    setFilter__onstock(onStock);
    debouncedSearch();
  };

  const FilterContent = (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="Filter articles">
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: "#FFF", background: "#000" }}>
              <Typography sx={{ fontSize: 14 }}>
                {t('searchFilterTitle')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <TableRow>
            <TableCell sx={{ background: "#f4f4f4", fontWeight: "bold", fontSize: 14 }}>
              {t('searchFilterByBrand')}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ paddingRight: 1 }}>
              {isLoadingResults ? (
                <Box sx={{ height: '200px' }}>
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                  <Skeleton height={30} />
                </Box>
              ) : (
                <Box 
                  sx={{ 
                    maxHeight: {
                      xs: '400px',  // mobile (xs)
                      sm: '100px',  // tablet (sm)
                      md: '300px',  // middle (md)
                      lg: '300px'   // large (lg and xl)
                    },
                    overflowY: 'auto' 
                  }}
                >
                  {brands_in_results && brands_in_results.length > 0 ? (
                    brands_in_results.map((brandObj) => (
                      <div key={brandObj.brand}>
                        <label>
                          <input
                            type="checkbox"
                            checked={filter__brands.includes(brandObj.brand) || brands_in_results.length === 1}
                            onChange={() => handle_CB_brand(brandObj.brand)}
                            disabled={brands_in_results.length === 1}
                          />
                          {brandObj.brand} ({Number(brandObj.count).toLocaleString('de-DE')})
                        </label>
                      </div>
                    ))
                  ) : (
                    <div>{t('searchFilterNoBrandsAvailable')}</div>
                  )}
                </Box>
              )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ background: "#f4f4f4", fontWeight: "bold", fontSize: 12 }}>
              <label style={{ fontSize: 14 }}>
                <Switch
                  onChange={() => handle_CB_stock(!filter__onstock)}
                  checked={filter__onstock === true}
                  inputProps={{ 'aria-label': 'On stock' }}
                  disabled={isLoadingResults || Number(onStock_in_results) === 0}
                />
                {t('searchFilterByOnStock')}
                {isLoadingResults ? (
                  <span>&nbsp;...</span>
                ) : (
                  <span>&nbsp;({Number(onStock_in_results).toLocaleString('de-DE')})</span>
                )}
              </label>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      {isMobile ? (
        // Botón para abrir el Drawer en dispositivos móviles
        <Box sx={{ marginBottom: 2 }}>
          <Button variant="contained" onClick={toggleDrawer} fullWidth>
            {t('searchFilterTitle')}
          </Button>
          <Drawer anchor="top" open={drawerOpen} onClose={toggleDrawer}>
            <Box sx={{ padding: 2 }}>
              {FilterContent}
            </Box>
          </Drawer>
        </Box>
      ) : (
        // Renderizar la tabla sin el Drawer en pantallas grandes
        FilterContent
      )}
    </>
  );
};

export default SearchFilter;
