import React from 'react';
import { Chip, Box, createTheme, ThemeProvider } from '@mui/material';
import useStore from '../../store/store';
import useHandleSearchService from '../../services/searchService.js';
import { useTranslation } from 'react-i18next';

const FilterChips = () => {
  const { t } = useTranslation();
  const { filter__brands, filter__onstock, setFilter__brands, setFilter__onstock } = useStore();
  const { handleSearchService } = useHandleSearchService();

  const handleDeleteBrand = (brand) => {
    setFilter__brands(filter__brands.filter(b => b !== brand));
    handleSearchService();
  };

  const handleDeleteOnStock = () => {
    setFilter__onstock(false);
    handleSearchService();
  };

  const handleRemoveAllFilters = () => {
    setFilter__brands([]);
    setFilter__onstock(false);
    handleSearchService();
  };

  // Custom theme (only for this component):
  const customTheme = createTheme({
    palette: {
      primary: {
        main: '#5a5a5a', // Background
        contrastText: '#ffffff', // Text
      },
    },
  });

  return (
    <ThemeProvider theme={customTheme}>
      <Box>
        {filter__brands.map(brand => (
          <Chip
            sx={{ mr: 0.5, mb: 0.5 }}
            key={brand}
            label={brand}
            onDelete={() => handleDeleteBrand(brand)}
          />
        ))}
        {filter__onstock && (
          <Chip
            sx={{ mr: 0.5, mb: 0.5 }}
            label={t('filterChipsOnStock')}
            onDelete={handleDeleteOnStock}
          />
        )}
        {(filter__brands.length > 0 || filter__onstock) && (
          <Chip
            sx={{ mr: 0.5, mb: 0.5 }}
            label={t('filterChipsRemoveAllFilters')}
            color="primary"
            onDelete={handleRemoveAllFilters}
          />
        )}
      </Box>
    </ThemeProvider>
  );
};
export default FilterChips;
