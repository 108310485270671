import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import config from './config';
import useStore from './store/store';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage.js';
import BetaFooter from './components/BetaOverlay/BetaFooter';


const App = () => {
  const {
    token,
    setUser,
    setToken,
    rememberMe,
  } = useStore();

  useEffect(() => {
    const checkAuth = async () => {
      if (!token) {
        return;
      }

      const response = await fetch(config.serverUrl, {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data.user);
      } else {
        setToken(null);
      }
    };

    checkAuth();
  }, [token, setToken, setUser]);

  // Clean state when not "remember me":
  useEffect(() => {
    if (!rememberMe) {
      const handleUnload = () => {
        useStore.getState().clearAuth();
      };
      window.addEventListener('beforeunload', handleUnload);

      return () => {
        window.removeEventListener('beforeunload', handleUnload);
      };
    }
  }, [rememberMe]);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route 
          path="/" 
          element={
            token ? 
            <HomePage />
             : 
            <Navigate to="/login" />
          } 
        />
        {/* Handle products and filters */}
        <Route 
          path="/product/:q"
          element={<HomePage />}
        />
        <Route 
          path="/product/:q/filter/:brands"
          element={<HomePage />}
        />
        <Route 
          path="/product/:q/filter/:brands/onstock"
          element={<HomePage />}
        />
        <Route 
          path="/product/:q/onstock"
          element={<HomePage />}
        />

        {/* Undefined routes */}
        <Route 
          path="*"
          element={<Navigate to="/" />}
        />        

      </Routes>

      { config.beta && <BetaFooter /> }
    </Router>
  );
};

export default App;
