import React from "react";
import useStore from "../../store/store";
import { useTranslation } from 'react-i18next';
import LoadingSpinner from "./LoadingSpinner_v2";

const LoadingOverlay = ({ loadingCondition = undefined }) => {
  const { t } = useTranslation();
  const { isLoadingResults } = useStore();

  // Use prop `loadingCondition` or `isLoadingResults` from Zustand.
  const isLoading = loadingCondition ?? isLoadingResults;

  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      opacity: isLoading ? 1 : 0,
      visibility: isLoading ? 'visible' : 'hidden',
      transition: 'opacity 0.7s ease',
    },
    message: {
      color: 'white',
      fontSize: '28px',
      fontWeight: 'bold',
      userSelect: 'none',
    }
  };

  return (
    <div 
      // @ts-ignore
      style={styles.overlay}
      aria-hidden={!isLoading} 
    >
      <LoadingSpinner />
      <div 
        // @ts-ignore 
        style={styles.message}>{t('loading')}...</div>
    </div>
  );
};

export default LoadingOverlay;
