// LoadingSpinner.js

import React from 'react';

const LoadingSpinner = () => {
  const spinnerStyles = {
    width: '88px',
    height: '88px',
    borderRadius: '50%',
    border: '14.1px solid',
    borderColor: '#ffffff',
    borderRightColor: '#112269',
    animation: 'spinner-d3wgkg 1s infinite linear',
    userSelect: 'none',
  };

  return (
    <>
      <div 
        // @ts-ignore
        style={spinnerStyles}></div>

      <style>
        {`
          @keyframes spinner-d3wgkg {
            to {transform: rotate(1turn);}
          }
        `}
      </style>
    </>
  );
};

export default LoadingSpinner;
