import React from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  FormGroup,
  Box,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import useStore from '../../store/store';
import useHandleSearchService from '../../services/searchService.js';
import { useTranslation } from 'react-i18next';

const SearchForm = () => {
  const { t } = useTranslation();
  const {
    shouldFocus,
    setShouldFocus,
    searchQuery,
    setSearchQuery,
    isLoadingResults,
    setFilter__brands,
    setFilter__onstock,
    setIsReplacementSearch,
  } = useStore();
  const { handleSearchService } = useHandleSearchService();

  const [highlighted, setHighlighted] = React.useState(false);
  const textFieldRef = React.useRef(null);

  React.useEffect(() => {
    if (shouldFocus) {
      // Highlight TextField 2 seconds
      setHighlighted(true);
      setTimeout(() => setHighlighted(false), 1000);
      // Reset focus
      setShouldFocus(false);
    }
  }, [shouldFocus, setShouldFocus]);

  async function doSearch() {
    setIsReplacementSearch(false); // Reset "replacement search" flag.
    setFilter__brands([]);
    setFilter__onstock(false);
    setSearchQuery(searchQuery.trim().replace(/^"|"$/g, ''));
    await handleSearchService();
  }
  
  const handleKeyPress = async (event) => {
    if (event.key === 'Enter') {
      await doSearch();
    }
  };
  
  const handleButtonClick = async () => {
    await doSearch();
  };
  const handleMouseEnter = () => {
    setHighlighted(false);
  };

  return (
    <FormGroup
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: { xs: "center", sm: "normal", md: "flex-end" },
        flexGrow: 1,
        flexDirection: { xs: "column", sm: "row", md: "row" },
        width: { xs: '100%', sm: 'auto' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'flex-end', sm: 'flex-start' },
          width: { xs: '100%', sm: 'auto' },
        }}
      >
        <TextField
          variant="outlined"
          size="small"
          placeholder={isLoadingResults ? `${searchQuery} ${t('searchFormLoading')}` : t('searchFormPlaceholder')}
          value={isLoadingResults ? '' : searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyPress}
          onMouseEnter={handleMouseEnter}
          ref={textFieldRef}
          sx={{
            backgroundColor: highlighted ? "rgba(255, 255, 255, 0.3)" : "rgba(255, 255, 255, 0.15)",
            borderRadius: { xs: 2, sm: 2, md: 2, lg: 2 },
            input: { color: "rgba(255, 255, 255, 1)" },
            width: {
              xs: "100%",
              sm: "38ch",
              md: "50ch",
              lg: "80ch",
            },
            transition: "width 0.3s, background-color 0.3s, box-shadow 0.3s",
            boxShadow: highlighted ? "0 0 10px 5px cyan" : "none",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: highlighted ? "2px solid cyan" : "none",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleButtonClick}
                  disabled={isLoadingResults}
                  sx={{ cursor: isLoadingResults ? "not-allowed" : "pointer" }}
                >
                  <SearchIcon sx={{ color: isLoadingResults ? "rgba(255, 255, 255, 0.5)" :"gold" }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'flex-end', sm: 'flex-start' },
          width: { xs: '100%', sm: 'auto' },
        }}
      >
        
      </Box>
    </FormGroup>
  );
};

export default SearchForm;
