import * as React from 'react';
import { Container, Divider, Tooltip } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import useStore from '../store/store';
import LogoutButton from "./LogoutButton";
import LanguageSelector from './LanguageSelector';

const SubHeader = () => {
    const user = useStore(state => state.user);
    return (
        <>
            <Container maxWidth="lg" sx={{ display: "flex", mt:0, mb:0.7, justifyContent: "flex-end", alignItems: "center" }}>    
                <LanguageSelector />&nbsp;            
                <Tooltip 
                    title={`${user?.username} (${user?.customer.name})`}
                    placement="left"
                    arrow 
                    slotProps={{
                    tooltip: {
                        sx: {
                            fontSize: '15px',
                            fontWeight: 'bold',
                            backgroundColor: '#d5d8dc',
                            color: '#2e4053',
                            padding: '10px',
                            borderRadius: '10px',
                            boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
                        },
                    },
                    arrow: { sx: {color: '#d5d8dc'} },
                    }}                       
                >
                    <PersonIcon sx={{ mr: 0.5, opacity: 0.9, cursor: 'pointer' }} color="primary" />
                </Tooltip>
                <LogoutButton />
            </Container>
            <Divider />
        </>
       
    );
}
export default SubHeader;
