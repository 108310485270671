
/**
 * Clean the input number and calculate the discounted price
 * @param {*} price 
 * @param {*} discount 
 * @returns formatted price.
 */
export const getNettolPrice = (price, discount) => {
  let cleanedPrice = price.replace(/[^0-9,.-]+/g, '').replace(',', '.');   
  let numericPrice = parseFloat(cleanedPrice);    
  if (isNaN(numericPrice)) {
    throw new Error('Invalid price format');
  }   
  let netPrice = numericPrice - ((numericPrice * discount) / 100);
  return netPrice.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' €';
};


/**
 * Format weight regarding the unit
 * @param {*} weight 
 * @param {*} unit 
 * @returns formatted weight
 */
export const getWeightByUnit = (weight, unit) => {
  if (isNaN(weight)) {
    return ' - ';
  }
  if (unit === 'kg') {
    return `${parseFloat(weight).toFixed(2).replace('.', ',')} kg`;
  } else if (unit === 'g') {
      return `${Math.round(weight)} g`;
  }
  return `${weight} ${unit}`;
};



