import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './pages/reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import EN_Translations from './locales/en.json';
import DE_Translations from './locales/de.json';
import FR_Translations from './locales/fr.json';
import ES_Translations from './locales/es.json';

// Initalize i18n:
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: EN_Translations },
      de: { translation: DE_Translations },
      fr: { translation: FR_Translations },
      es: { translation: ES_Translations },
    },
    lng: localStorage.getItem('language') || 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, 
    },
  });

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  );
} else {
  console.error("No root element found in the DOM");
}

// Performance measurement (WebVitals) optional:
reportWebVitals();
