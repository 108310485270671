import React from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useStore from '../../store/store.js';

// Función para dividir las marcas en un número dinámico de columnas
const splitBrands = (brands, numColumns) => {
  const itemsPerColumn = Math.ceil(brands.length / numColumns);
  return Array.from({ length: numColumns }, (_, index) =>
    brands.slice(index * itemsPerColumn, (index + 1) * itemsPerColumn)
  );
};

const BrandsList = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  // Subscribe to the property:
  const pricetool_brands = useStore((state) => state.pricetool_brands);

  // Determinamos el número de columnas según el tamaño de pantalla
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  let numColumns;
  if (isXs) {
    numColumns = 2; // Para pantallas móviles
  } else if (isSm) {
    numColumns = 3; // Para pantallas pequeñas
  } else if (isMd || isLg) {
    numColumns = 4; // Para pantallas medianas y grandes
  }

  const columns = splitBrands(pricetool_brands, numColumns);

  return (
    <Box
      sx={{
        backgroundColor: "#d0e7ff",
        padding: "10px",
        borderRadius: "4px",
        mt: 2,
        mb: 2,
      }}
    >
      <Typography variant="h6" gutterBottom>
        {t('welcomeBrandsIncluded')}
      </Typography>

      {pricetool_brands.length > 0 ? (
        <Grid container spacing={2}>
          {Array.isArray(columns) && columns.length > 0 && columns.map((columnBrands, columnIndex) => (
            <Grid
              item
              xs={6}    // Dos columnas en pantallas extra pequeñas
              sm={4}    // Tres columnas en pantallas pequeñas
              md={3}    // Cuatro columnas en pantallas medianas y grandes
              lg={3}
              key={columnIndex}
            >
              <ul className="brand-column" style={{ listStyleType: "none", paddingLeft: 0 }}>
                {Array.isArray(columnBrands) && columnBrands.length > 0 ? (
                  columnBrands.map((brand, index) => (
                    <li key={index}>
                      <strong dangerouslySetInnerHTML={{ __html: brand }} />
                    </li>
                  ))
                ) : (
                  <li>Loading...</li>
                )}
              </ul>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography>{t('welcomeNoBrands')}</Typography>
      )}
    </Box>
  );
};

export default BrandsList;
