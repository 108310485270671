import axios from 'axios';
import config from '../config';
import useStore from '../store/store';
import { useNavigate, useLocation } from 'react-router-dom';

const useHandleSearchService = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearchService = async (searchParams = {}, isReplacementSearch = false) => {
    const {
      searchQuery,
      exactMatch,
      setSearchResults,
      setSearchQuerySnap,
      setBrands_in_results,
      setOnStock_in_results,
      setSearchError,
      setCurrentPage,
      setHasSearched,
      filter__brands,
      filter__onstock,
      setIsLoadingResults,
      setIsReplacementSearch,
      //setSearchQuery,
      user,
      loading_From_URL, 
      setLoading_From_URL,
    } = useStore.getState();

    const urlParams = location.pathname.split('/');
    let urlQuery = urlParams[2] !== 'all' ? urlParams[2] : '';
    let urlBrands = (urlParams[3] === 'filter' && urlParams[4]) ? urlParams[4].split(',') : [];
    let urlOnStock = urlParams.includes('onstock');

    // Use `searchQuery` if it's a regular search, or `replacementQuery` in replacement searches
    const query = Array.isArray(searchParams.q) 
      ? searchParams.q[0]  // Use the first element of replacements for sequential search
      : searchParams.q || (loading_From_URL ? urlQuery : searchQuery);
      
    const onstock = searchParams.onstock !== undefined 
      ? searchParams.onstock 
      : (loading_From_URL ? urlOnStock : filter__onstock);
    
    let brands = searchParams.brands || (loading_From_URL && urlBrands.length > 0 ? urlBrands : filter__brands || []);

    if (loading_From_URL) {
      setLoading_From_URL(false);
    }

    const updateURL = () => {
      const queryStr = searchQuery || 'all'; // Show original query in URL
      const brandStr = brands.length > 0 ? brands.join(',') : '';
      const onStockStr = onstock ? 'onstock' : '';

      const filterPart = brandStr ? `filter/${brandStr}` : '';
      const onStockPart = onStockStr ? `/${onStockStr}` : '';

      const newUrl = `/product/${queryStr}${filterPart ? '/' + filterPart : ''}${onStockPart}`;
      if (newUrl !== location.pathname) {
        navigate(newUrl);
      }
    };

    if (!query || query.trim() === '') {
      setIsLoadingResults(false);
      return;
    }

    setIsLoadingResults(true);
    const minimumLoadingTime = new Promise((resolve) => setTimeout(resolve, 1500));

    // Prepare the parameters for the search query
    const params = {
      q: query.trim().replace(/^"|"$/g, ''),
      userid: (user && user.id) || null,
    };
    if (exactMatch) {
      params.exact = true;
    }
    if (brands.length > 0) {
      params.brands = JSON.stringify(brands);
    }
    if (onstock) {
      params.onstock = onstock;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.href = '/login';
        return;
      }

      // Execute the primary search query
      const response = await axios.get(`${config.serverUrl}/search`, {
        params: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      // If no results found and not already performing a "replacement" search
      // ----------------------------------------------------------------------
      // NOTE: This is a temporary workaround to disable replacement search.
      // PLEASE REMOVE "false" WHEN REPLACEMENT SEARCH IS ENABLED AGAIN.
      // ----------------------------------------------------------------------
      if (false && response.data.articles.length === 0 && !isReplacementSearch) {
        // Execute replacement query if no articles are found
        const replacementResponse = await axios.get(`${config.serverUrl}/replacements`, {
          params: { q: query },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        });

        // If replacement articles are found, perform sequential search
        if (replacementResponse.data.replacements.length > 0) {
          const replacementQueries = replacementResponse.data.replacements;
          
          setIsReplacementSearch(true); // Mark as replacement search in Zustand
          
          // Accumulate results from each replacement query
          const accumulatedResults = [];
          const accumulatedBrands = [];
          let accumulatedOnStock = 0;

          // Sequentially search each replacement query
          for (const replacementQuery of replacementQueries) {
            // Perform search for each replacement article
            const replacementResult = await axios.get(`${config.serverUrl}/search`, {
              params: { q: replacementQuery, userid: params.userid },
              headers: { Authorization: `Bearer ${token}` },
              withCredentials: true,
            });

            if (replacementResult.data.articles.length > 0) {
              accumulatedResults.push(...replacementResult.data.articles);
              accumulatedBrands.push(...replacementResult.data.brands);
              accumulatedOnStock += replacementResult.data.onStock;
            }
          }

          // Check if any results were accumulated
          if (accumulatedResults.length > 0) {
            setHasSearched(true);
            setSearchResults(accumulatedResults);
            setBrands_in_results(accumulatedBrands);
            setOnStock_in_results(accumulatedOnStock);
            setSearchError(false);
            setSearchQuerySnap(searchQuery);  // Keep original query in Snap
            updateURL();
            return;
          }
        }

        // If no results in replacement search, show error
        setSearchError(true);
        setSearchResults([]);  // Clear results
        setSearchQuerySnap(searchQuery);  // Keep original query in Snap
        setIsLoadingResults(false);
        setBrands_in_results([]);
        setOnStock_in_results(0);
        return;
      }

      // If results found in original or replacement search
      setHasSearched(true);
      setSearchResults(response.data.articles);
      setBrands_in_results(response.data.brands);
      setOnStock_in_results(response.data.onStock);
      setSearchError(false);
      setSearchQuerySnap(searchQuery);  // Keep original query in Snap
      updateURL();

    } catch (err) {
      setSearchError(true);
      setSearchResults([]);  // Clear results
      setBrands_in_results([]);
      setOnStock_in_results(0);
      window.location.href = '/login';
    } finally {
      setCurrentPage(1);  // Reset to first page
      await minimumLoadingTime;
      setIsLoadingResults(false);
    }
  };

  return { handleSearchService };
};

export default useHandleSearchService;
