import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import React from 'react';
import useStore from '../../store/store';
import { useTranslation } from 'react-i18next';
const SearchErrorModal = () => {
  const { t } = useTranslation();
  const {
    searchError, setSearchError, searchQuery, hasSearched
  } = useStore();

  const handleClose = () => setSearchError(false);

  // This modal works only on "Home" page.
  if (hasSearched) return null;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
      xs: '90%',  // Mobile (xs)
      sm: 550     // Other screens.
    },
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 0,
    p: 4,
    outline: 0,
  };

  return (
    searchError && (
    <Modal
      open={searchError}
      onClose={handleClose}
      sx={{ outline: 0 }}
    >
      <Paper sx={style}>
        <Typography sx={{ fontSize: 120, textAlign: 'center' }}> ⚠️ </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 20 }}> {t('searchErrorModalText')}: </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 30, fontWeight: 'bold', wordBreak: 'break-word', lineHeight: 1 }}> {searchQuery} </Typography>
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button onClick={handleClose}>{t('searchErrorModalButton')}</Button>
        </Box>
      </Paper>
    </Modal>
  ))
}

export default SearchErrorModal;