import React from 'react';
import useStore from '../../store/store';
import { Box, Typography, Grid } from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HelpIcon from '@mui/icons-material/Help';
import { useTranslation } from 'react-i18next';

const SearchBottomBanner = () => {
  const { t } = useTranslation();
  const { hasSearched } = useStore();
  
  return (   
    <>
      {hasSearched && (
      <Grid container columns={12} sx={{ p: 0 }}>
        <Grid item sm={12} md={6}>
          <Box sx={{ p: 0, mt: 2, mb: 2, mr: { xs: 0, sm: 0, md: 2 }, borderRadius: 2, background: '#d1f2eb', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 6px' }}>
            <Typography sx={{ fontSize: 24, color: '#17a589', fontWeight: 'bold', lineHeight: 1, p: 1, m:0 }}>
              <AssignmentTurnedInIcon sx={{ verticalAlign: 'middle', mr: 0, fontSize: 36 }} /> {t('readyToOrder')}
            </Typography>

            <Typography sx={{ fontSize: 16, color: '#17a589', background: '#e8f8f5', p:2 }}>          
              {t('orderInstructions')}
            </Typography> 
          </Box>
        </Grid>

        <Grid item sm={12} md={6}>
          <Box sx={{ p: 0, mt: 2, mb: 2, borderRadius: 2, background: '#f9e79f', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 2px 6px' }}>
            <Typography sx={{ fontSize: 24, color: '#b7950b', fontWeight: 'bold', lineHeight: 1, p: 1, m:0 }}>
              <HelpIcon sx={{ verticalAlign: 'middle', mr: 0, fontSize: 36 }} /> {t('notWhatYoureLookingFor')}
            </Typography>

            <Typography sx={{ fontSize: 16, color: '#b7950b', background: '#fef9e7', p:2 }}>          
              {t('contactInstructions')}
            </Typography> 
          </Box>
        </Grid>
      </Grid>
      )}
    </>
  );
};

export default SearchBottomBanner;
