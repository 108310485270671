import React from 'react';
import { AppBar, Box, Container, Link, Toolbar, Typography } from '@mui/material';
import SellIcon from '@mui/icons-material/Sell';
import SearchForm from '../SearchEngine/SearchForm';
import { useNavigate } from 'react-router-dom';
import useStore from '../../store/store';

const TopBar = () => {
  const navigate = useNavigate();
  const { resetSearch } = useStore();
  
  const handleClick = () => {
    resetSearch();
    navigate('/');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed"
        sx={{ padding:{ xs: 1, sm: 1, md: 0, lg: 0} }}>
        <Container maxWidth="lg" sx={{ p: 0 }}>
          <Toolbar
            sx={{
              p: 0,
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
            }}
          >
          <Link onClick={handleClick} sx={{ textDecoration: 'none', cursor: 'pointer' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 1, sm: 1, md: 0, lg: 0 } }}>
              <SellIcon
                sx={{
                  mr: 0.5,
                  opacity: 0.8,
                  color: 'cyan',
                  fontSize: '2.4rem'
                }}
              />
              <Typography
                variant="h4"
                noWrap
                component="div"
                sx={{
                  fontWeight: 'bold',
                  color: 'white',               
                }}
              >
                Price Tool 
                <span style={{ color: 'gold', fontWeight: 'normal', fontSize: '0.5em', marginLeft: 6 }}>beta</span>
              </Typography>
            </Box>          
          </Link>  
            
            <SearchForm />
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default TopBar;