import React from 'react';
import { Box, Typography } from '@mui/material';


const BetaFooter = () => {

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#f2d7d5',
        zIndex: 1000
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 40,
          borderTop: '1px solid #c0392b'
        }}
      >
        <Typography sx={{ fontSize: 16, color: '#c0392b', lineHeight: 1, fontWeight: 'bold' }}>
            This is a beta version of the "Price Tool". Please use it with caution.
        </Typography> 
      </Box>
    </Box>
  );
};

export default BetaFooter;