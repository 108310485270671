import { useLocation } from 'react-router-dom';
import useHandleSearchService from '../services/searchService.js';
import useStore from '../store/store.js';

const useCheckUrlForSearchParams = () => {
  const location = useLocation(); // Get current location
  const { handleSearchService } = useHandleSearchService();

  const checkParams = (location) => {
    // Extract URL parts
    const urlParams = location.pathname.split('/');

    if (urlParams[1] === 'product') {
      // Get search query parameter
      const urlQuery = urlParams[2] !== 'all' ? urlParams[2] : '';
      // Get brands from URL
      const urlBrands = (urlParams[3] === 'filter' && urlParams[4]) ? urlParams[4].split(',') : [];
      // Check if "onstock" is present in the URL
      const urlOnStock = urlParams.includes('onstock');

      // Get current state
      const {
        searchQuery,
        filter__brands,
        filter__onstock,
        setSearchQuery,
        setBrands_in_results,
        setOnStock_in_results,
        setLoading_From_URL,
      } = useStore.getState();

      // Avoid unnecessary updates if state already matches URL
      const needsUpdate =
        urlQuery !== searchQuery ||
        JSON.stringify(urlBrands) !== JSON.stringify(filter__brands) ||
        urlOnStock !== filter__onstock;

      if (needsUpdate) {
        // Synchronize state with URL
        setSearchQuery(urlQuery);
        setBrands_in_results(urlBrands);
        setOnStock_in_results(urlOnStock);
        setLoading_From_URL(true); // Mark that we are loading from the URL

        // Call the search service with URL parameters
        handleSearchService({
          q: urlQuery,
          brands: urlBrands,
          onstock: urlOnStock,
        });
      }
    } else {
      // If it doesn't match the "/product" route, disable loading from URL
      useStore.getState().setLoading_From_URL(false);
    }
  };

  return { checkParams: () => checkParams(location) };
};

export default useCheckUrlForSearchParams;